"use client";
import OnlineChatSvg from "@/svg/socials/onlineChat.svg";
import ChatSvg from "@/svg/socials/chat.svg";
import PhoneSvg from "@/svg/socials/phone.svg";
// import WhatsAppSvg from "@/svg/socials/whatsapp.svg";
import WhatsAppSvg from "@/svg/socials/w_buss.svg";
import { useState } from "react";
import PhoneCall from "./PhoneCall";
import OnlineChat from "./OnlineChat";
import SocketProvider from "@/contexts/SocketContext";

export default function FastContacts() {
    const [openState, setOpenState] = useState(() => {
        if (typeof window !== "undefined") {
            const chatStatus = window.localStorage.getItem("chtOpn");
            if (chatStatus) {
                if (JSON.parse(chatStatus || false)) return "chat";
            }
            return false;
        }
        return false;
    });

    return (
        <>
            <div
                className={`fast-contacts ${
                    openState ? "fast-" + openState : ""
                }`}
                onClick={({ target }) => {
                    if (target.classList.contains("fast-phone")) {
                        setOpenState("menu");
                    }
                }}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div
                        className="show-head"
                        onClick={() => {
                            setOpenState((prev) => (prev ? false : "menu"));
                        }}
                    >
                        <ChatSvg />
                    </div>

                    <div className="select-option">
                        <div
                            className="option"
                            onClick={() => {
                                setOpenState("phone");
                            }}
                        >
                            <div className="tip">Заказать звонок</div>
                            <PhoneSvg />
                        </div>
                        <div
                            className="option"
                            onClick={() => {
                                setOpenState("chat");
                                window.localStorage.setItem(
                                    "chtOpn",
                                    JSON.stringify(true)
                                );
                            }}
                        >
                            <div className="tip">Онлайн Чат</div>
                            <OnlineChatSvg />
                        </div>
                    </div>

                    {openState === "chat" ? (
                        <SocketProvider>
                            <OnlineChat close={setOpenState} />
                        </SocketProvider>
                    ) : (
                        ""
                    )}
                    {openState === "phone" ? (
                        <PhoneCall closeLayer={setOpenState} />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
}
