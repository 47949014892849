"use client";

import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";

export default function CheckPageClass() {
    const [pageClass, setPageClass] = useState(false);
    // const [preload, setPreload] = useState(true);
    const headerRef = useRef(null);
    const pathname = usePathname();

    useEffect(() => {
        if (headerRef.current) {
            const header = headerRef.current.parentElement;
            if (header.classList.contains("preload"))
                header.classList.remove("preload");
        }
    }, [headerRef.current]);
    useEffect(() => {
        if (headerRef.current) {
            const header = headerRef.current.parentElement;
            const activeClass = "static-header";
            const isClassed = header.classList.contains(activeClass);

            if (pageClass && !isClassed) {
                header.classList.add(activeClass);
            }
            if (!pageClass && isClassed) {
                header.classList.remove(activeClass);
            }
        }
    }, [pageClass]);

    useEffect(() => {
        if (headerRef.current) {
            if (
                pathname === "/" ||
                pathname === "/about" ||
                pathname === "/nashi-tseny"
            ) {
                setPageClass(false);
            } else {
                setPageClass(true);
            }
        }
    }, [pathname]);
    return <div ref={headerRef} />;
}
