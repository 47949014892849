"use client";
import { useEffect, useState } from "react";
import MobBtnSvg from "@/svg/mobHeadBtn.svg";
import Link from "next/link";

export default function MobBtn() {
    const mobWidth = useCheckScreenWidth();
    const [openSub, setOpenSub] = useState(false);
    if (!mobWidth) return "";

    return (
        <>
            <div className="mob-btn" onClick={openMenu}>
                <MobBtnSvg />
            </div>
            <div className="mob-menu">
                <div className="close" />
                <div className="menu-list" onClick={openMenu}>
                    <Link href={"/"}>Главная</Link>
                    <Link href={"/about"}>О компании</Link>
                    <div
                        className={`submenu ${openSub ? `opened` : ""}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenSub((prev) => !prev);
                        }}
                    >
                        <div className="title">Автомобили</div>
                        <div className={`mini-menu `} onClick={openMenu}>
                            <Link href={"/catalog?country=usa"}>
                                Авто из США
                            </Link>
                            <Link href={"/catalog?country=ge"}>
                                Авто из Грузия
                            </Link>
                            <Link href={"/catalog?country=eu"}>
                                Авто из Европы
                            </Link>
                        </div>
                    </div>
                    <Link href={"/dogovor"}>Информация</Link>
                    <Link href={"/nashi-tseny"}>Наши цены</Link>
                    {/* <Link href={"/calculator"}>Калькулятор</Link> */}
                    <Link href={"/kontakty"}>Контакты</Link>
                </div>
            </div>
        </>
    );

    function openMenu() {
        const headerClass = "open-mob-menu";
        const header = document.querySelector("header");
        if (header.classList.contains(headerClass)) {
            header.classList.remove(headerClass);
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "hidden";
            header.classList.add(headerClass);
        }
    }
}

const useCheckScreenWidth = () => {
    const [mobWidth, setMobWidth] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            if (window.innerWidth < 1000) {
                setMobWidth(true);
            } else {
                setMobWidth(false);
            }
        };

        // Add event listener to check screen width on resize
        window.addEventListener("resize", checkScreenWidth);

        // Initial check on component mount
        checkScreenWidth();

        // Cleanup by removing event listener on component unmount
        return () => {
            window.removeEventListener("resize", checkScreenWidth);
        };
    }, []);

    return mobWidth;
};
