"use client";

import { useEffect, useRef, useState } from "react";

export default function CheckOnScroll() {
    const [scrolled, setScrolled] = useState(false);
    const [preScrolled, setPreScrolled] = useState(false);

    const headerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition =
                window.scrollY || document.documentElement.scrollTop;
            const windowHeight =
                window.innerHeight || document.documentElement.clientHeight;
            // const contentHeight = document.documentElement.scrollHeight;

            if (scrollPosition >= windowHeight) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }

            if (scrollPosition > 100 && scrollPosition < windowHeight) {
                setPreScrolled(true);
            } else {
                setPreScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (headerRef.current) {
            const header = headerRef.current.parentElement;
            const activeClass = "scrolled";

            const isClassed = header.classList.contains(activeClass);

            if (scrolled && !isClassed) {
                header.classList.add(activeClass);
            }

            if (!scrolled && isClassed) {
                header.classList.remove(activeClass);
            }
        }
    }, [scrolled, headerRef]);

    useEffect(() => {
        if (headerRef.current) {
            const header = headerRef.current.parentElement;
            const activeClass = "pre-scrolled";

            const isClassed = header.classList.contains(activeClass);

            if (preScrolled && !isClassed) {
                header.classList.add(activeClass);
            }

            if (!preScrolled && isClassed) {
                header.classList.remove(activeClass);
            }
        }
    }, [preScrolled, headerRef]);

    return <div className="scrl-hldr" ref={headerRef} />;
}
