import ArrowSvg from "@/svg/fullArrow.svg";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";

export default function PhoneCall({ closeLayer }) {
    const [phone, setPhone] = useState("");
    return (
        <div className="phone-call">
            <PhoneInput
                country={"ru"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                containerClass="forms-input"
                dropdownClass="forms-dropdown"
            />
            <div className="btn" onClick={sendPhone}>
                <ArrowSvg />
            </div>
        </div>
    );

    function sendPhone() {
        fetch("/api/notify", {
            method: "POST",
            body: JSON.stringify(`Быстрая форма возле чата\n\n${phone}`),
        });
        closeLayer(false);
    }
}
