"use client";
import { useEffect, useRef, useState } from "react";
import ArrowSvg from "@/svg/fullArrow.svg";
import { useSocket } from "@/contexts/SocketContext";

import moment from "moment";
import "moment/locale/ru";
import { toast } from "react-toastify";
import Image from "next/image";
moment.locale("ru");

export default function OnlineChat({ close }) {
    const [msg, setMsg] = useState("");
    const [messageContainer, setMessageContainer] = useState(false);
    const [firstTimeChat, setFirstTimeChat] = useState(false);

    const [minimize, setMinimize] = useState(() => {
        // if (window) {
        const miniStatus = window.localStorage.getItem("chtMin");
        if (miniStatus) {
            return JSON.parse(miniStatus);
        }
        return false;
        // }
    });
    const scrolledItem = useRef(null);
    useEffect(() => {
        if (scrolledItem.current) {
            scrolledItem.current.scrollIntoView();
        }
    }, [messageContainer]);
    const { socket } = useSocket();

    useEffect(() => {
        if (socket) {
            socket.on("first_msg", (status) => {
                if (status) {
                    setFirstTimeChat((prev) => false);
                    addMsg(status);
                }
            });
            socket.on("new_msg", (msg) => {
                addMsg(msg);
            });
        }
        if (!messageContainer && socket) {
            socket.emit("chat_history");
            socket.on("chat_history", (history) => {
                setMessageContainer(history);
            });
        }
    }, [socket]);

    return (
        <div className={`online-chat ${minimize ? "minimize" : ""}`}>
            <div className="chat-head">
                <div className="img">
                    <Image
                        src={"/src/imgs/manager.jpg"}
                        fill
                        sizes="100%"
                        alt="manager"
                    />
                </div>
                <span>Менеджер</span>
                <div className="btns">
                    <div
                        className="btn hide"
                        onClick={() => {
                            setMinimize((prev) => {
                                const newStat = !prev;
                                window.localStorage.setItem(
                                    "chtMin",
                                    JSON.stringify(newStat)
                                );
                                return newStat;
                            });
                        }}
                    >
                        <ArrowSvg />
                    </div>
                    <div
                        className="btn close"
                        onClick={() => {
                            window.localStorage.removeItem("chtOpn");
                            close(false);
                        }}
                    >
                        X
                    </div>
                </div>
            </div>
            <div className="chat-content">
                <div className="history-overflow">
                    <div className="history">{renderMessages()}</div>
                    <div ref={scrolledItem} />
                </div>

                <div className="send">
                    <textarea
                        type="text"
                        placeholder="Ваше сообщение"
                        value={msg}
                        onChange={changeMsg}
                        onKeyDown={(event) => {
                            if (
                                event.key === "Enter" &&
                                (event.ctrlKey || event.metaKey)
                            ) {
                                setMsg((prev) => prev + "\n");
                            } else if (event.key === "Enter") {
                                event.preventDefault();
                                sendMsg();
                            }
                        }}
                    />
                    <div className="btn" onClick={sendMsg}>
                        <ArrowSvg />
                    </div>
                </div>
            </div>
        </div>
    );

    function renderMessages() {
        if (messageContainer === false)
            return <div className="loader animate-spin" />;

        if (messageContainer.length === 0) {
            return (
                <div className={`not-me-msg`}>
                    <p>Добрый день, что вас интересует?</p>
                    <span className="time">
                        {moment(new Date()).format("dd HH:mm")}
                    </span>
                </div>
            );
        }

        return messageContainer.map((item) => {
            return (
                <div
                    key={item?._id ?? item.timestamp}
                    className={item.sender ? "me-msg" : "not-me-msg"}
                >
                    <p>{item.message}</p>
                    <span className="time">
                        {moment(item.timestamp).format("dd HH:mm")}
                    </span>
                </div>
            );
        });
        // .reverse();
    }

    function changeMsg({ target }) {
        setMsg(target.value);
    }
    function addMsg(msg) {
        setMessageContainer((prev) => {
            if (prev.length === 0 && !msg.sender) {
                toast.info("Новое сообщение в чате");
            }
            return [msg, ...(prev ?? [])];
        });
    }
    function sendMsg() {
        if (!msg) return;
        if (firstTimeChat) return ("WAIT UNTIL FIRST MSG");
        if (messageContainer === false) return;

        const newMsg = { message: msg, sender: true };
        if (messageContainer.length > 0) {
            socket.emit("new_msg", newMsg);
        } else {
            socket.emit("first_msg", newMsg);
            setFirstTimeChat((prev) => true);
        }
        setMsg("");
        // setMessageContainer((prev) => [...prev, msg]);
    }
}
