import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/components/DefaultButton.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/FastContacts/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/Footer/GoogleMapIframe.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/Header/ActiveLink.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/Header/CheckOnScroll.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/Header/CheckPageClass.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/Header/MobBtn.jsx");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/logo.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/phone.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/fb.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/insta.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/telega.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/tiktok.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/vk.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/whatsapp.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/youtube.svg");
